import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { getDividendMonth } from '../../helpers';

import 'twin.macro';

import PortfolioSummary from './PortfolioSummary';
import PortfolioTable from './PortfolioTable';
import SectorTable from './SectorTable';

const Main = ({ portfolio }) => {
  const portfolioSum = useMemo(() => {
    if (Object.keys(portfolio).length !== 0) {
      return portfolio
        .map((value) => value.price * value.quantity)
        .reduce((prev, next) => prev + next);
    }
    return 0;
  }, [portfolio]);

  const months = useMemo(
    () => [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ],
    []
  );

  const dividends = useMemo(() => {
    if (portfolio == null) return Array(12).fill(0);
    return months.map((month) =>
      Math.round(getDividendMonth(portfolio, `2023-${month}`))
    );
  }, [portfolio, months]);

  const year = dividends.reduce((prev, next) => prev + next);

  const DA = useMemo(() => {
    if (portfolio != null && portfolio.length > 0) {
      return (year / portfolioSum) * 100;
    }
    return 0;
  }, [portfolio, year, portfolioSum]);

  return (
    <div tw="block flex-auto h-full flex-wrap overflow-y-auto overflow-x-hidden! bg-gray-100 max-md:pb-[68px]">
      <PortfolioSummary portfolio={portfolio} da={DA} total={portfolioSum} />
      <PortfolioTable data={portfolio} total={portfolioSum} />
      <SectorTable data={portfolio} total={portfolioSum} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps)(Main);
