import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import tw, { styled } from 'twin.macro';

import Message from '../Message';
import ModalContainer from '../Modal';

import {
  addDividend,
  closeStockModal,
  deleteDividend,
  editDividend,
  editGav,
} from '../../actions/stockActions';

const DividendList = styled.ul`
  ${tw``}

  li {
    ${tw`flex place-content-between items-center border-gray-200 border-b py-2 max-sm:space-y-2 last-of-type:border-b-0 md:space-x-4`}
  }

  label {
    ${tw`py-1 font-semibold`}
  }
`;

class EditDividendModal extends React.PureComponent {
  static propTypes = {
    modalactive: PropTypes.bool,
    modaldata: PropTypes.string,
    closeStockModal: PropTypes.func,
  };

  state = {
    exDate: '2023-01-01',
    gav: this.props.gav,
    stock: null,
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState = () => {
    const stock = this.props.portfolio.filter(
      (item) => this.props.modaldata === item.api_id
    );
    this.setState({
      stock: stock[0],
    });
  };

  edit = (event, index) => {
    const exDate = event.target.name;
    const dividendAmount = event.target.value;
    this.props.editDividend(
      this.props.modaldata,
      exDate,
      Number(dividendAmount) || 0,
      this.state.stock.currency_multiply,
      index
    );
  };

  addexDate = (event) => {
    this.setState({
      exDate: event.target.value,
    });
  };

  addDividend = () => {
    this.props.addDividend(this.props.modaldata, this.state.exDate, 1);
  };

  renderDividends = () => {
    if (this.state.stock !== null) {
      return this.state.stock.dividends.map((dividend, i) => {
        const price = (
          dividend.amount * this.state.stock.currency_multiply
        ).toFixed(2);
        const price_int = Number(price);
        return (
          <li key={i} tw="flex items-center px-4 max-sm:(flex-wrap pt-2 pb-4!)">
            <p tw="max-sm:(w-full py-2) md:w-1/3">{dividend.exDate}</p>
            {/* TODO: Probably want to make a better check for this */}
            {dividend.paymentDate !== undefined ? (
              <input
                disabled
                tw="grow max-sm:w-full"
                type="number"
                min="0"
                name={dividend.exDate}
                placeholder={price_int + ' kr'}
              />
            ) : (
              <input
                onChange={(e) => this.edit(e, i)}
                tw="grow max-sm:w-full"
                type="number"
                min="0"
                name={dividend.exDate}
                placeholder={price_int + ' kr'}
              />
            )}
            {/* TODO: Probably want to make a better check for this */}
            {dividend.paymentDate !== undefined ? (
              <button
                tw="max-sm:w-full"
                className="destructive disabled"
                disabled
              >
                Ta bort
              </button>
            ) : (
              <button
                className="destructive"
                tw="max-sm:w-full"
                onClick={() => {
                  this.props.deleteDividend(
                    dividend.exDate,
                    this.state.stock.api_id,
                    i
                  );
                }}
              >
                Ta bort
              </button>
            )}
          </li>
        );
      });
    }
  };

  // GAV

  editGav = () => {
    this.props.editGav(this.props.modaldata, this.state.gav);
  };

  render() {
    return this.state.stock ? (
      <ModalContainer
        title={'Redigera ' + this.state.stock.name}
        close={this.props.closeStockModal}
      >
        <div tw="mb-4 rounded border border-gray-200">
          <h3 tw="bg-gray-50 px-4 pt-3 pb-3 font-bold">
            Ange GAV för {this.state.stock.name}
          </h3>
          <hr tw="mb-2" />
          <div tw="flex items-center px-4 py-2 max-sm:(flex-wrap space-y-2) md:space-x-2">
            <input
              tw="grow"
              type="number"
              min="0"
              name="add_gav"
              placeholder={
                this.state.stock.gav != null
                  ? this.state.stock.gav.toFixed(2)
                  : 'GAV ej inställt'
              }
              onChange={(e) => this.setState({ gav: Number(e.target.value) })}
            />
            <button
              className="primary"
              tw="max-sm:(m-0 w-full)"
              onClick={() => {
                this.editGav();
              }}
            >
              Uppdatera GAV
            </button>
          </div>
          <span tw="ml-1 block px-4 pt-1 pb-4 italic">
            GAV behöver anges i SEK för att visas korrekt.
          </span>
        </div>
        <div tw="rounded border border-gray-200 pb-4">
          <h3 tw="bg-gray-50 px-4 pt-3 pb-3 font-bold">Utdelningar</h3>
          <hr tw="md:mb-2" />
          {this.renderDividends() !== '' ? (
            <DividendList>
              <li tw="w-full px-4 pt-0! max-sm:hidden! md:flex">
                <label htmlFor="dividend_exdate" tw="w-1/3 font-normal!">
                  Handlas utan utdelning
                </label>
                <label htmlFor="dividend" tw="grow font-normal!">
                  Utdelning per aktie
                </label>
              </li>
              {this.renderDividends()}{' '}
            </DividendList>
          ) : (
            <div tw="m-4">
              <Message type="info">
                Vi har ingen data om utdelning för detta bolag just nu. Testa
                att lägg till manuellt eller uppdatera datan från Avanza vid ett
                senare tillfälle.
              </Message>
            </div>
          )}
          <div>
            <hr tw="mb-4" />
            <div tw="flex items-center px-4 max-sm:(flex-wrap space-y-2) md:space-x-2">
              <input
                tw="grow"
                type="date"
                min="2023-01-01"
                max="2023-12-31"
                name="add_dividend"
                defaultValue="2023-01-01"
                onChange={this.addexDate}
              />
              <button
                className="primary"
                add="true"
                onClick={this.addDividend}
                tw="max-sm:w-full"
              >
                Lägg till utdelning detta datum{' '}
              </button>
            </div>
            <span tw="ml-1 mt-2 block px-4 italic">
              Ange endast 2023 i format yyyy-mm-dd. Ange utdelningen i SEK. Du
              kan inte ta bort utdelningar som läses in från nätmäklare.
            </span>
          </div>
        </div>
      </ModalContainer>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    modaldata: state.appReducer.modaldata,
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps, {
  closeStockModal,
  editDividend,
  deleteDividend,
  addDividend,
  editGav,
})(EditDividendModal);
