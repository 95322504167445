import React from 'react';

import tw, { styled } from 'twin.macro';

const Container = styled.div`
  ${tw`fixed inset-0 z-50 flex max-h-full w-full place-content-center items-center bg-transparent p-4 max-lg:px-4`}

  aside {
    ${tw`absolute inset-0 z-50 block bg-black/60 backdrop-blur-sm`}
  }

  > div {
    ${tw`w-full space-y-4 rounded bg-white pt-4 pb-2 z-[60] md:w-[40rem]`}
  }

  header {
    ${tw`flex place-content-between items-center px-4`}
  }
`;

export default function ModalContainer({ children, title, close }) {
  return (
    <Container>
      <aside onClick={close} />
      <div tw="flex max-h-full flex-col">
        <header>
          <h2 tw="font-bold text-lg">{title}</h2>
          <div tw="cursor-pointer text-lg" onClick={close}>
            ✖
          </div>
        </header>
        <div tw="max-h-full overflow-y-auto overflow-x-hidden border-gray-200 border-t px-4 py-5">
          {children}
        </div>
      </div>
    </Container>
  );
}
