import React from 'react';

import tw, { styled } from 'twin.macro';

import Loading from '../../images/loading.svg';

const Load = styled.div`
  ${tw`absolute inset-0 m-auto flex w-full place-content-center items-center bg-white/50 p-4 backdrop-blur-sm transition z-[60]`}

  span {
    ${tw`space-y-4 text-center`}
  }
  p {
    ${tw`font-bold text-xl text-black`}
  }
  img {
    width: 7rem;
    display: inline-block;
    height: 1.7rem;
  }
`;

function SidebarLoading() {
  return (
    <Load>
      <span>
        <p>Hämtar aktiedata</p>
        <img src={Loading} alt="loading" />
      </span>
    </Load>
  );
}

export default SidebarLoading;
