import Plausible from 'plausible-tracker';
import React, { useState } from 'react';

import { connect } from 'react-redux';
import 'twin.macro';
import {
  deleteStock,
  editStock,
  editStockModal,
} from '../../actions/stockActions';
import FlagComponent from '../General/Flag';
import PortfolioUpdate from './PortfolioUpdate';

import { Edit2, X } from 'lucide-react';

const { trackEvent } = Plausible({
  domain: 'aktieutdelningar.now.sh',
  apiHost: 'https://ping.arvdsn.co',
});

const SidebarPortfolio = ({
  stocks,
  editStock,
  deleteStock,
  editStockModal,
  searchValue,
}) => {
  const [timeout, setTimeout] = useState(null);

  const editQuantity = (event) => {
    const api_id = event.target.name;
    const quantity = event.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeout(() => {
      editStock(quantity, api_id);
    }, 500);
  };

  const renderStock = () => {
    stocks.sort((a, b) => a.name.localeCompare(b.name));
    return stocks.map((item, index) => {
      return (
        <li key={index} tw="flex items-center space-x-2">
          <span
            onClick={() => {
              deleteStock(item.api_id);
              trackEvent('Stock: Removed', {
                props: {
                  name: item.name,
                  country: item.country_code && item.country_code.toLowerCase(),
                },
              });
            }}
          >
            <X tw="cursor-pointer" color="black" size={16} />
          </span>
          <input
            type="number"
            min="1"
            name={item.api_id}
            onChange={editQuantity}
            placeholder={item.quantity}
            tw="rounded-sm border py-1.5! px-1.5! font-mono w-[56px]"
          />
          <div
            onClick={() => {
              editStockModal(item.api_id);
            }}
            className="group"
            tw="flex w-full cursor-pointer place-content-between items-center space-x-1 pr-2"
          >
            {' '}
            <div tw="flex items-center space-x-2">
              <FlagComponent country={item.country_code} />{' '}
              <span tw="block text-base lg:text-sm xl:text-base">
                {item.name}
              </span>
            </div>
            <Edit2 color="black" size={16} />
          </div>
        </li>
      );
    });
  };

  return Object.keys(stocks).length !== 0 ? (
    <React.Fragment>
      <PortfolioUpdate />
      {searchValue === '' ? (
        <ul tw="space-y-1.5 overflow-y-auto px-4 pt-4 pb-20">
          {renderStock()}
        </ul>
      ) : null}
    </React.Fragment>
  ) : null;
};

function mapStateToProps(state) {
  return {
    stocks: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps, {
  editStock,
  deleteStock,
  editStockModal,
})(SidebarPortfolio);
