import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'twin.macro';
import Plausible from 'plausible-tracker';

import { addStock } from '../../actions/stockActions';
import stockData from '../../stocklist.json';
import Message from '../Message';
import FlagComponent from '../General/Flag';

import { Plus } from 'lucide-react';

const { trackEvent } = Plausible({
  domain: 'aktieutdelningar.now.sh',
  apiHost: 'https://ping.arvdsn.co',
});

const SidebarSearch = ({ addStock, portfolio, onInputChange }) => {
  const [stocks, setStocks] = useState(stockData);
  const [result, setResult] = useState(stockData);
  const [input, setInput] = useState('');

  const renderStockList = () => {
    if (input.length > 0) {
      if (result.length === 0) {
        return (
          <div tw="mx-4 my-2">
            <Message type="warning">
              {' '}
              <p>
                <strong>Kunde inte hitta "{input}".</strong>
              </p>
              <p>
                Om du upplever att detta värdepappret borde finnas, så skicka
                iväg en förfrågan till oss så lägger vi till det.
              </p>
              <p>
                <Link
                  tw="font-semibold text-blue-600 transition hover:text-blue-700"
                  to="/tools"
                >
                  Kontakta oss här →
                </Link>
              </p>
            </Message>
          </div>
        );
      } else {
        return (
          <ul tw="h-full overflow-y-auto px-4 pt-2 pb-20">
            {result.slice(0, 25).map((item, i) => (
              <li
                onClick={() => {
                  trackEvent('Stock: Added', {
                    props: {
                      name: item.name,
                      country: item.country_code.toLowerCase(),
                    },
                  });
                  if (
                    !portfolio.some((stock) => stock.api_id === item.api_id)
                  ) {
                    addStock(item.name, item.api_id);
                    clearInput();
                  } else {
                    alert(item.name + ' finns redan tillagd i listan.');
                  }
                }}
                key={i}
                tw="flex cursor-pointer place-content-between items-center border-gray-200 border-b py-3 pl-1 pr-3 font-medium text-base"
              >
                <div tw="flex items-center space-x-2.5">
                  <FlagComponent country={item.country_code} />{' '}
                  <span tw="font-normal">{item.name}</span>
                </div>
                <Plus color="black" size={20} />
              </li>
            ))}
          </ul>
        );
      }
    }
  };

  const filterStocks = (event) => {
    setInput(event.target.value);
    onInputChange(event.target.value);
    let result = [];
    result = stocks.filter((stock) => {
      return (
        stock.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    setResult(result);
  };

  const clearInput = () => {
    setInput('');
    onInputChange('');
  };

  return (
    <React.Fragment>
      <div tw="relative px-4">
        <input
          type="text"
          tw="w-full"
          placeholder="Sök och lägg till aktie"
          onChange={filterStocks}
          value={input}
        />
        {input && (
          <svg
            onClick={() => {
              clearInput();
            }}
            tw="absolute right-7 top-3 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        )}
      </div>
      {renderStockList()}
    </React.Fragment>
  );
};

export default connect(null, { addStock })(SidebarSearch);
