import React from 'react';
import { connect } from 'react-redux';
import 'twin.macro';

import { Helmet } from 'react-helmet';
import Message from '../components/Message';

import { importData } from '../actions/stockActions';

const title = 'Exportera/importera data | Aktieutdelningar';
const desc =
  'Var med och testa nya versionen av Aktieutdelningar, utvecklad från grunden med bättre användarvänlighet och nya funktioner.';

const LocalStorageData = ({ header, state: data }) => {
  const dataStr = JSON.stringify(data);

  const handleDownload = () => {
    const currentDate = new Date();
    const fileName = `aktieutdelningar-${currentDate
      .toISOString()
      .slice(0, 16)
      .replace(/[T:]/g, '-')}.json`;
    const blob = new Blob([dataStr], {
      type: 'application/json',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <main className="content" tw="m-auto mt-0 max-w-xl space-y-3 text-base">
        {header && (
          <>
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🪴
              </span>
              <span>Exportera data</span>
            </h1>
          </>
        )}
        {!header && <h3>Exportera data</h3>}
        <p>
          Här ser du ett utdrag av hela den datan som sammanfattar alla
          värdepapper du har lagt till i appen. Denna data kan du spara till en
          textfil (behöver ha filändelsen .json om du vill kunna importera
          igen).
        </p>
        <p tw="mb-3!">
          Du kan ladda ner din data till en fil direkt, som du sedan kan ladda
          upp på valfri annan enhet. Samtliga värdepapper kommer då att laddas
          upp på nya enheten.
        </p>
        <Message type="info">
          Notera dock att ändringar du gör på en enhet <strong>inte</strong>{' '}
          kommer att ändras på övriga enheter. Det finns alltså alltså ingen
          synk mellan dina enheter. Detta bör ses som en avancerad funktion.
        </Message>
        <textarea
          tw="h-56 w-full rounded pl-3 pr-4 text-sm"
          value={dataStr}
          readOnly={true}
        />
        <div tw="flex space-x-3">
          <button
            className="secondary"
            onClick={() => navigator.clipboard.writeText(dataStr)}
          >
            Kopiera till utklipp
          </button>
          <button className="secondary" onClick={handleDownload}>
            Spara som fil
          </button>
        </div>
      </main>
    </>
  );
};

export default connect(
  (state) => ({ state: { portfolioReducer: state.portfolioReducer } }),
  { importData }
)(LocalStorageData);
