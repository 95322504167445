import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import 'twin.macro';

import { getDividendMonth } from '../../helpers';
import PorfolioSum from './PorfolioSum';
import PieStocks from './PieStocks';
import PieSector from './PieSector';
import DividendSumTable from './DividendSumTable';
import YearTable from './YearTable';
import StockTable from './StockTable';
import DividendTable from '../Dividends/DividendTable';
import Message from '../Message';

const Main = ({ portfolio }) => {
  const [showReferrerMessage, setShowReferrerMessage] = useState(false);

  useEffect(() => {
    const referrer = document.referrer;
    console.log(referrer);
    if (
      referrer ===
      ('https://referrer-test.vercel.app/' ||
        'https://aktieutdelningar.netlify.app/')
    ) {
      setShowReferrerMessage(true);
    }
  }, []);

  let portfolioSum = 0;
  if (Object.keys(portfolio).length !== 0) {
    portfolioSum = portfolio
      .map((value) => value.price * value.quantity)
      .reduce((prev, next) => prev + next);
  }

  const jan =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-01')) : 0;
  const feb =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-02')) : 0;
  const mar =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-03')) : 0;
  const apr =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-04')) : 0;
  const may =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-05')) : 0;
  const jun =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-06')) : 0;
  const jul =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-07')) : 0;
  const aug =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-08')) : 0;
  const sep =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-09')) : 0;
  const okt =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-10')) : 0;
  const nov =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-11')) : 0;
  const dec =
    portfolio != null ? Math.round(getDividendMonth(portfolio, '2023-12')) : 0;

  const q1 = jan + feb + mar;
  const q2 = apr + may + jun;
  const q3 = jul + aug + sep;
  const q4 = okt + nov + dec;

  const half1 = q1 + q2;
  const half2 = q3 + q4;

  const year = half1 + half2;

  let DA = 0;
  if (Object.keys(portfolio).length !== 0) {
    DA = (year / portfolioSum) * 100;
  }

  const data = {
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    okt,
    nov,
    dec,
    q1,
    q2,
    q3,
    q4,
    half1,
    half2,
  };

  return (
    <div tw="flex flex-auto h-full flex-wrap space-y-4 overflow-y-auto overflow-x-hidden! bg-gray-100 max-md:pb-[68px] lg:block">
      {showReferrerMessage && (
        <div tw="-mb-4 bg-white px-4 pt-4">
          <Message type="warning">
            <p tw="font-semibold text-base">Hej kära besökare! 👋</p>
            <p>
              Vi ser att du besöker siten från{' '}
              <em tw="font-semibold">https://aktieutdelningar.netlify.app</em>.
              Detta är en gammal address som snart kan sluta fungera. Om du har
              ett bokmärke så bör du uppdatera detta till vår nya address
              istället för att slippa eventuellt avbrott:
            </p>
            <p>
              <a
                tw="font-semibold text-blue-500"
                href="https://aktieutdelningar.now.sh"
              >
                https://aktieutdelningar.now.sh
              </a>
            </p>
          </Message>
        </div>
      )}
      {portfolio.length > 0 ? (
        <>
          <div tw="grid w-full grid-cols-1 items-center gap-6 border-gray-200 border-b bg-white pt-8 pb-12 md:px-12 lg:grid-cols-3">
            <PorfolioSum portfolioSum={portfolioSum} DA={DA} />
            <PieStocks portfolio={portfolio} />
            <PieSector portfolio={portfolio} />
          </div>
        </>
      ) : (
        <></>
      )}
      <DividendSumTable year={year} />
      <YearTable data={data} />
      <StockTable />
      <DividendTable data={portfolio} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  portfolio: state.portfolioReducer.portfolio,
});

export default connect(mapStateToProps)(Main);
