import React from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

const title = 'Exportera/importera data | Aktieutdelningar';
const desc =
  'Var med och testa nya versionen av Aktieutdelningar, utvecklad från grunden med bättre användarvänlighet och nya funktioner.';

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <Layout>
        <main tw="mt-0 h-full w-full overflow-y-auto py-8 pb-24 max-md:px-4">
          <div className="content" tw="m-auto max-w-xl space-y-3 text-base">
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🙋
              </span>
              <span>Vanliga frågor och svar</span>
            </h1>
            <h2 tw="pt-4">Vad är statusen på nya Aktieutdelningar?</h2>
            <p>
              Just nu sker ingen aktiv utveckling på nya applikationen, men den
              bör fortfarande fungera. Orsaken till vilan är att vi som driver
              detta projektet har fullt upp med våra vanliga day to day arbeten,
              samt att vi har stött på lite begränsningar i underliggande tech
              stack som vi har byggt appen på.
            </p>
            <p>
              Det är egentligen inga större problem, men kräver att vi tänker
              till vad nästa steg kommer att vara. Vi har en plan vi filar på,
              men behöver göra lite ytterligare research för att komma vidare.
              Det är dock fritt fram att använda nya appen idag, och ingen data
              kommer gå förlorad när vi utvecklar vidare.
            </p>
            <p>
              <a href="https://app.aktieutdelningar.co">
                Testa nya Aktieutdelningar här
              </a>
            </p>
            <h2 tw="pt-4">Hur sparas min data?</h2>
            <p>
              Din portfölj sparas bara i din webbläsares{' '}
              <a
                rel="nofollow noopener"
                href="https://www.w3schools.com/html/html5_webstorage.asp"
                target="blank"
              >
                localstorage
              </a>
              , vilket innebär att du slipper skapa ett konto.
            </p>
            <p>
              Det innebär dock att det bara är du som kommer kunna se din
              portfölj, eftersom det bara är du som har din webbläsare.
              Kortfattat, det är bara du och din webbläsare. Vill du se
              portföljen i både din dator, andra datorer och telefoner så måste
              du skapa den i respektive enhet.
            </p>
            <p>
              Vi har en beta-funktion som exporterar och importerar din data
              till andra enheter som du kan testa. Du hittar den{' '}
              <Link to="/tools">här</Link>.
            </p>
            <h2 tw="pt-4">Hur hämtas aktiedatan?</h2>
            <p>
              All aktiedata hämtas från Avanza varje gång du lägger till ett
              värdepapper i din portfölj. Vi gör ingen hämtning av data när du
              uppdaterar antalet innehav på respektive värdepapper Aktiedata,
              men om du upplever att innehavet inte stämmer så kan du klicka på
              knappen "Uppdatera aktiekurser".
            </p>
            <h2 tw="pt-4">Utdelningen stämmer inte eller saknas. Varför?</h2>
            <p>
              Då all utdelningsdata hämtas från Avanza för det nuvarande året,
              kan det ibland hända att data inte stämmer (splittar av bolag)
              eller att Avanza inte har datan (amerikanska aktier). Då finns
              möjligheten att editera utdelningar för respektive aktie genom att
              klicka på den lilla pennan bredvid ditt innehav.
            </p>
            <p>
              I början av ett år så tar det lite extra tid innan Avanza lägger
              till utdelningar.
            </p>
            <h2 tw="pt-4">Använder hemsidan cookies eller liknande?</h2>
            <p>
              Appen använder sig av Plausible Analytics istället för Google
              Analytics för att bevara din integritet som användare. Du kan läsa
              mer om Plausible och GDPR på{' '}
              <a href="https://plausible.io/data-policy">deras hemsida</a>.
              Ingen data om enskilda användare sparas.
            </p>
            <p>
              I övrigt lagrar vi, som beskrivet ovan, data i localstorage men
              använder oss inte av några cookies för någon del av tjänsten.
            </p>
            <h2 tw="pt-4">Vem skapade denna webbapp?</h2>
            <p>
              Dick Törnfeldt har utvecklat appen, men la ned tjänsten på grund
              av tidsbrist en tid tillbaka. Om du uppskattar tiden som har lagts
              ner för att utveckla verktyget så kontakta honom gärna och berätta
              det. Dick går att nå via{' '}
              <a
                rel="nofollow"
                href="https://twitter.com/dicktornfeldt"
                target="blank"
              >
                {' '}
                hans Twitter-profil
              </a>
            </p>
            <p>
              Appen drivs nu vidare av{' '}
              <a href="https://patrikarvidsson.com">mig</a> och min bror som ett
              sidoprojekt. Tyvärr har vi begränsat med tid men vi försöker
              allokera tillräckligt för att hålla siten vid liv.
            </p>
            <p>
              Om ni stöter på något fel eller något som inte fungerar kan ni
              skicka ett mail till{' '}
              <a href="mailto:patrik@aktieutdelningar.co">
                patrik[at]aktieutdelningar.co
              </a>{' '}
              så kollar vi på det snarast möjligt!
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default FAQ;
