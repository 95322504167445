import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import throttle from 'lodash.throttle';
import Plausible from 'plausible-tracker';

import { GlobalStyles } from 'twin.macro';

import store, { history } from './store';
import App from './App';
import { saveState } from './reducers/localStorage';

const { trackPageview, trackEvent, enableAutoPageviews } = Plausible({
  domain: 'aktieutdelningar.now.sh',
  apiHost: 'https://ping.arvdsn.co',
  trackLocalhost: 'true',
});

trackPageview();
trackEvent();
enableAutoPageviews();

// persist state to localstorage
store.subscribe(
  throttle(() => {
    const state = store.getState();

    saveState({
      portfolioReducer: state.portfolioReducer,
      backupReducer: state.backupReducer,
    });
  }, 1000)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <GlobalStyles />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </>
);
