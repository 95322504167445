import React from 'react';

import FlagSE from '../../images/flags/se.svg';
import FlagUS from '../../images/flags/us.svg';
import FlagDK from '../../images/flags/dk.svg';
import FlagFR from '../../images/flags/fr.svg';
import FlagFI from '../../images/flags/fi.svg';
import FlagNO from '../../images/flags/no.svg';
import FlagCA from '../../images/flags/ca.svg';
import FlagDE from '../../images/flags/de.svg';

export default function FlagComponent({ country }) {
  let imgSrc;
  switch (country) {
    case 'SE':
      imgSrc = FlagSE;
      break;
    case 'US':
      imgSrc = FlagUS;
      break;
    case 'DK':
      imgSrc = FlagDK;
      break;
    case 'FR':
      imgSrc = FlagFR;
      break;
    case 'FI':
      imgSrc = FlagFI;
      break;
    case 'NO':
      imgSrc = FlagNO;
      break;
    case 'CA':
      imgSrc = FlagCA;
      break;
    case 'DE':
      imgSrc = FlagDE;
      break;
    default:
      imgSrc = '';
  }
  return country !== (null || undefined) ? <img src={imgSrc} alt="flag" /> : '';
}
