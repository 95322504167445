import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import portfolioReducer from './portfolioReducer';
import appReducer from './appReducer';
import backupReducer from './backupReducer';

const reducer = combineReducers({
  router: routerReducer,
  portfolioReducer,
  appReducer,
  backupReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'IMPORT_DATA') {
    return {
      ...state,
      backupReducer: backupReducer(state.backupReducer, {
        type: 'ADD_BACKUP',
        date: action.date,
        data: { portfolioReducer: state.portfolioReducer },
      }),
      ...action.data,
    };
  }

  return reducer(state, action);
};

export default rootReducer;
