import React from 'react';

import tw, { styled } from 'twin.macro';

const TooltipContainer = styled.div`
  ${tw`relative top-3 mx-4 hidden rounded bg-blue-600 p-2 text-white after:content-['']`}

  p {
    ${tw`m-0`}
  }

  &:after {
    ${tw`border-blue-600`}

    content: '';
    position: absolute;
    top: 4px;
    left: 15%;
    width: 0px;
    height: 0px;
    border-width: 0px 0.8rem 0.8rem;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    margin-left: -1rem;
    margin-top: -1rem;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Tooltip = () => (
  <TooltipContainer>
    <p>
      Sök efter och lägg till din första aktie. Välj sedan hur många du äger av
      den.
    </p>
  </TooltipContainer>
);

export default Tooltip;
