import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PortfolioUpdate from '../components/Sidebar/PortfolioUpdate';

import Logo from '../images/logo.svg';

import { AnimatePresence, motion } from 'framer-motion';

import NewsData from '../news.json';

import { Menu, X } from 'lucide-react';

import 'twin.macro';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuBackdropActive, setMenuBackdropActive] = useState(false);
  const [menuItemsVisible, setMenuItemsVisible] = useState(false);

  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  const recentNews = NewsData.filter((news) => {
    const newsDate = new Date(news.date);
    return newsDate >= threeDaysAgo;
  });

  const menuState = {
    open: { x: 0 },
    closed: { x: '100%' },
  };

  const menuBackdrop = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  const menuItems = [
    { text: 'Översikt', to: '/overview' },
    { text: 'Portfölj', to: '/holdings' },
    { text: 'Rapporter', to: '/reports' },
    { text: 'Bloggar', to: '/blogs' },
    { text: '', to: '' },
    { text: 'Nyheter', to: '/news' },
    { text: 'FAQ', to: '/faq' },
    { text: 'Verktyg', to: '/tools' },
  ];

  const listItemContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { delayChildren: 0.25, staggerChildren: 0.04 },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: 0 },
    show: { opacity: 1, x: -12 },
  };

  return (
    <>
      {recentNews.length > 0 && (
        <div tw="relative z-20 block flex items-center space-x-2 bg-green-600 px-4 py-2.5 font-mono text-white text-xs">
          <span>
            <strong>NYHET:</strong> {NewsData[0].title}
          </span>
          {NewsData[0].content !== '' ? (
            <Link tw="font-bold max-md:hidden" to="/news">
              <span tw="max-md:hidden">Läs mer </span>→
            </Link>
          ) : (
            ''
          )}
        </div>
      )}

      <AnimatePresence>
        {menuOpen && (
          <motion.aside
            tw="fixed right-0 bottom-0 z-50 flex h-full flex-col bg-black/80 px-12 pt-6 pb-12 text-white shadow-xl backdrop-blur-3xl w-[90%]"
            key="menuFrame"
            variants={menuState}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
          >
            <button
              tw="absolute top-10 right-4 p-2"
              onClick={() => {
                setMenuOpen(false);
                setMenuBackdropActive(false);
              }}
            >
              <X color="white" size={24} />
            </button>

            <nav tw="flex h-full w-full items-center pl-8">
              {menuItemsVisible && (
                <motion.ul
                  tw="space-y-4 font-medium text-xl"
                  variants={listItemContainer}
                  initial="hidden"
                  animate="show"
                  transition={{ delay: 10 }}
                >
                  {menuItems.map((item, i) => (
                    <motion.li
                      key={i}
                      variants={listItemVariants}
                      tw="min-h-[12px]"
                      onClick={() => {
                        setMenuOpen(false);
                        setMenuBackdropActive(false);
                      }}
                    >
                      <Link to={item.to}>{item.text}</Link>
                    </motion.li>
                  ))}
                </motion.ul>
              )}
            </nav>
          </motion.aside>
        )}
        {menuBackdropActive && (
          <motion.div
            tw="absolute inset-0 h-screen w-screen bg-black/70 z-[49]"
            key="modalBg"
            variants={menuBackdrop}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.3 }}
            onClick={() => {
              setMenuOpen(false);
              setMenuBackdropActive(false);
            }}
          ></motion.div>
        )}
      </AnimatePresence>

      <header tw="relative z-20 flex place-content-between items-center bg-black py-3.5 pl-3.5 pr-3 font-mono text-white text-sm md:pr-4">
        <div tw="font-medium">
          <Link to="/" tw="flex items-center space-x-3">
            <img tw="h-6 w-6" src={Logo} alt="Aktieutdelningar" />
            <span tw="inline-block">Aktieutdelningar</span>
          </Link>
        </div>

        <nav>
          <ul tw="flex items-center space-x-6 md:hidden">
            <li>
              <PortfolioUpdate tiny />
            </li>
            <li
              onClick={() => {
                setMenuOpen(true);
                setMenuBackdropActive(true);
                setMenuItemsVisible(true);
              }}
            >
              <Menu color="white" size={24} />
            </li>
          </ul>
          <ul tw="hidden space-x-4 md:block">
            <li tw="inline">
              <Link to="/news">Nyheter</Link>
            </li>
            <li tw="inline">
              <Link to="/faq">FAQ</Link>
            </li>
            <li tw="inline">
              <Link to="/tools">Verktyg</Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
