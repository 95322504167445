import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { updatePortfolio } from '../../actions/stockActions';

import tw, { styled } from 'twin.macro';

const PortfolioUpdate = ({
  stocks,
  updatePortfolio,
  small,
  tiny,
  children,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateComplete, setUpdateComplete] = useState(true);

  const updateStocks = useCallback(async () => {
    if (Object.keys(stocks).length !== 0 && !isUpdating) {
      setUpdateComplete(false);
      setIsUpdating(true);
      try {
        await updatePortfolio(stocks).then(() => {
          setIsUpdating(false);
          setUpdateComplete(true);
        });
      } catch (error) {
        setIsUpdating(false);
        setUpdateComplete(true);
      }
    }
  }, [isUpdating, updatePortfolio, stocks]);

  const Icon = styled.svg(() => [
    tw`m-auto stroke-2`,
    !updateComplete && tw`block animate-spin`,
  ]);

  const UpdateNotice = () => {
    return (
      <>
        {isUpdating && (
          <div tw="fixed top-0 left-0 w-full bg-blue-500 px-3 py-2 text-sm text-white z-[9999]">
            Uppdaterar aktiekurser, vänligen vänta...
          </div>
        )}
      </>
    );
  };

  return Object.keys(stocks).length !== 0 ? (
    <>
      {small !== true ? /*
          <button className="primary" tw="hidden w-full" onClick={updateStocks}>
          Uppdatera aktiekurser
          </button>
          </>*/ null : (
        <>
          <UpdateNotice />
          <div
            onClick={updateStocks}
            tw="cursor-pointer space-y-2 py-4 text-black"
          >
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path d="M21 2v6h-6"></path>
              <path d="M21 13a9 9 0 1 1-3-7.7L21 8"></path>
            </Icon>
            {children}
          </div>
        </>
      )}
      {tiny && (
        <>
          <UpdateNotice />
          <div onClick={updateStocks} tw="cursor-pointer text-white">
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path d="M21 2v6h-6"></path>
              <path d="M21 13a9 9 0 1 1-3-7.7L21 8"></path>
            </Icon>
            {children}
          </div>
        </>
      )}
    </>
  ) : null;
};

function mapStateToProps(state) {
  return {
    stocks: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps, { updatePortfolio })(PortfolioUpdate);
