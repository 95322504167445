import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import 'twin.macro';

import ReportsTable from './ReportsTable';

const Main = ({ portfolio }) => {
  const portfolioReports = useMemo(() => portfolio, [portfolio]);

  return (
    <div tw="flex flex-auto h-full flex-wrap overflow-y-auto overflow-x-hidden! bg-gray-100 max-md:pb-[68px] lg:block">
      <ReportsTable data={portfolioReports} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps)(Main);
