import React from 'react';
import tw from 'twin.macro';

const Parent = tw.div`inline-flex w-1/2 flex-col bg-white font-mono xl:(w-full flex-row)`;
const Child = tw.div`w-full border-b`;
const Cell = tw.div`flex-1 border-gray-200 py-3 text-center xl:(border-r py-4)`;
const Head = tw.div`border-gray-200 border-r bg-gray-50 py-3 text-center font-extrabold`;

class YearTable extends React.PureComponent {
  render() {
    return (
      <div tw="w-full border-t">
        <Parent>
          <Child>
            <Head>
              <p>Första halvåret</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Andra halvåret</p>
            </Head>
          </Child>
        </Parent>

        <Parent>
          <Child>
            <Cell>
              <p>{this.props.data.half1}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.half2}:-</p>
            </Cell>
          </Child>
        </Parent>
        <Parent>
          <Child>
            <Head>
              <p>Q1</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Q2</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Q3</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Q4</p>
            </Head>
          </Child>
        </Parent>
        <Parent>
          <Child>
            <Cell>
              <p>{this.props.data.q1}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.q2}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.q3}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.q4}:-</p>
            </Cell>
          </Child>
        </Parent>
        <Parent>
          <Child>
            <Head>
              <p>Jan</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Feb</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Mar</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Apr</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Maj</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Jun</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Jul</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Aug</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Sep</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Okt</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Nov</p>
            </Head>
          </Child>
          <Child>
            <Head>
              <p>Dec</p>
            </Head>
          </Child>
        </Parent>
        <Parent>
          <Child>
            <Cell>
              <p>{this.props.data.jan}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.feb}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.mar}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.apr}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.may}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.jun}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.jul}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.aug}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.sep}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.okt}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.nov}:-</p>
            </Cell>
          </Child>
          <Child>
            <Cell>
              <p>{this.props.data.dec}:-</p>
            </Cell>
          </Child>
        </Parent>
      </div>
    );
  }
}

export default YearTable;
