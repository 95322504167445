import React, { useState } from 'react';

import tw, { styled } from 'twin.macro';

import FlagComponent from '../General/Flag';

import ChevronDown from '../../images/icons/ic_chevron_down.svg';
import ChevronUp from '../../images/icons/ic_chevron_up.svg';

const Parent = tw.div`flex w-full flex-wrap place-content-start`;
const Child = tw.div`w-full`;
const Cell = tw.div`grid grid-cols-12 border-gray-200 border-t-0 text-center font-mono`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

const PortfolioTable = ({ data, total }) => {
  const [currentTab, setCurrentTab] = useState(1);

  // Portföljfördelning
  const calculateInvestedCash = (price, quantity) => {
    let investedCash = price * quantity;

    return investedCash.toFixed(0);
  };

  const calculatePercentage = (price, quantity) => {
    return (((price * quantity) / total) * 100).toFixed(2);
  };

  const sortedData = [...data].sort(
    (a, b) =>
      calculatePercentage(b.price, b.quantity, b.currency_multiply) -
      calculatePercentage(a.price, a.quantity, a.currency_multiply)
  );

  return (
    <div tw="mb-3 block w-full bg-white">
      <Parent tw="border-gray-200 border-t">
        <Child tw="cursor-pointer" onClick={() => setCurrentTab(!currentTab)}>
          <Head tw="flex place-content-between items-center">
            <p>Innehav</p>
            <div>
              {currentTab ? (
                <img src={ChevronUp} alt="" />
              ) : (
                <img src={ChevronDown} alt="" />
              )}
            </div>
          </Head>
        </Child>
        {data.length > 0 ? (
          <>
            <Child css={[currentTab ? tw`block` : tw`hidden`]}>
              <Cell>
                <div tw="col-span-12 flex place-content-between items-center space-x-2 border-gray-200 border-b px-4 py-2.5 text-left max-2xl:hidden 2xl:col-span-6">
                  <div>Värdepapper</div>
                </div>
                <div tw="col-span-12 border-gray-200 border-b px-4 py-2 max-2xl:hidden 2xl:(col-span-6 grid grid-cols-5 p-0)">
                  <div tw="border-gray-200 border-l py-3 px-4 text-left text-gray-500">
                    Antal
                  </div>
                  <div tw="border-gray-200 border-l py-3 px-4 text-left text-gray-500">
                    GAV
                  </div>
                  <div tw="border-gray-200 border-l py-3 px-4 text-left text-gray-500">
                    Kurs
                  </div>
                  <div tw="border-gray-200 border-l px-4 py-3 text-left text-gray-500">
                    Kapital
                  </div>
                  <div tw="border-gray-200 border-l px-4 py-3 text-left text-gray-500">
                    Andel
                  </div>
                </div>
                {sortedData.map((stock, index) => {
                  const appreciation =
                    stock.gav != null ? (
                      (100 * ((stock.price - stock.gav) / stock.gav)).toFixed(2)
                    ) : (
                      <abbr title="Lägg till ditt GAV genom att klicka på ett värdepapper till vänster.">
                        N/A
                      </abbr>
                    );

                  const MobileLabel = tw.span`block 2xl:hidden`;
                  const Column = styled.div`
                    ${tw`flex place-content-between items-center py-1.5 lg:(block space-y-1 border-gray-200 border-r px-4 py-2.5 text-left) 2xl:(border-r-0 border-l)`}

                    span {
                      ${tw`lg:(block w-full)`}
                    }

                    span:first-of-type {
                      ${tw`block lg:text-gray-500 2xl:hidden`}
                    }
                  `;

                  return (
                    <React.Fragment key={index}>
                      {/* NAME */}
                      <div tw="col-span-12 flex items-center space-x-4 border-gray-200 border-b px-4 py-3 text-left max-2xl:bg-gray-50 2xl:(col-span-6 place-content-between)">
                        <div tw="flex items-center space-x-2">
                          <FlagComponent country={stock.country_code} />
                          <span>{stock.name}</span>
                        </div>
                        <div tw="2xl:pt-1">
                          {appreciation > 0 ? (
                            <div tw="font-semibold text-green-600">
                              {appreciation}%
                            </div>
                          ) : appreciation < 0 ? (
                            <div tw="font-semibold text-red-600">
                              {appreciation}%
                            </div>
                          ) : (
                            <>
                              {/*<div tw="text-gray-400">{appreciation}</div>*/}
                            </>
                          )}
                        </div>
                      </div>

                      <div tw="col-span-12 border-gray-200 border-b px-4 py-2 lg:(grid grid-cols-5 p-0) 2xl:col-span-6">
                        {/* AMOUNT */}
                        <Column>
                          <MobileLabel>Antal</MobileLabel>
                          <span>{stock.quantity}</span>
                        </Column>
                        {/* GAV */}
                        <Column>
                          <MobileLabel>GAV</MobileLabel>
                          <span>
                            {stock.gav != null ? (
                              stock.gav.toFixed(2) + ':-'
                            ) : (
                              <abbr
                                tw="text-gray-400"
                                title="Lägg till ditt GAV genom att klicka på ett värdepapper till vänster."
                              >
                                N/A
                              </abbr>
                            )}
                          </span>
                        </Column>
                        {/* PRICE */}
                        <Column>
                          <MobileLabel>Kurs</MobileLabel>
                          <span>{stock.price.toFixed(2)}:-</span>
                        </Column>
                        {/* CAPITAL */}
                        <Column>
                          <MobileLabel>Kapital</MobileLabel>
                          <span>
                            {calculateInvestedCash(stock.price, stock.quantity)}
                            :-
                          </span>
                        </Column>
                        {/* ANDEL */}
                        <Column>
                          <MobileLabel>Andel av portfölj</MobileLabel>
                          <span>
                            {calculatePercentage(stock.price, stock.quantity)}%
                          </span>
                        </Column>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Cell>
            </Child>
          </>
        ) : (
          <>
            <Child css={[currentTab ? tw`block` : tw`hidden`]}>
              <Cell>
                <div tw="col-span-12 flex items-center border-b px-4 py-3 text-left">
                  Du behöver lägga till värdeppaper för att kunna se data kring
                  ditt innehav. Du kan göra det i listan till vänster.
                </div>
              </Cell>
            </Child>
          </>
        )}
      </Parent>
    </div>
  );
};

export default PortfolioTable;
