import React from 'react';

import Layout from '../components/Layout';
import Main from '../components/Blogs';
import Sidebar from '../components/Sidebar';
import Toolbar from '../components/Toolbar';

export default function OverviewView() {
  return (
    <>
      <Sidebar mobile />
      <Layout>
        <Toolbar />
        <Sidebar desktop />
        <Main />
      </Layout>
    </>
  );
}
