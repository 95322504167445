import React from 'react';
import 'twin.macro';

import generatedGitInfo from '../generatedGitInfo.json';

export default function Footer() {
  return (
    <>
      <footer tw="relative z-50 flex place-content-between border-gray-200 border-t bg-white px-4 py-2 font-mono text-gray-500 text-xs max-md:hidden">
        <div tw="items-center space-x-1 text-black max-md:w-1/2">
          <a href="/beta">Testa nya appen</a>
          <div tw="inline-block rounded bg-blue-500 py-px px-1 font-bold text-xs text-white">
            BETA
          </div>
        </div>
        <div tw="flex items-center space-x-2 max-md:max-w-[50%]">
          <div tw="block h-1.5 w-1.5 rounded-full bg-green-500"></div>
          <code tw="overflow-hidden text-ellipsis">
            {generatedGitInfo.gitBranch && generatedGitInfo.gitBranch}
            {generatedGitInfo.gitCommitHash && (
              <>
                /
                <a
                  href={
                    'https://github.com/aktieutdelningar/app-react/commit/' +
                    generatedGitInfo.gitCommitHash
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {generatedGitInfo.gitCommitHash}
                </a>
              </>
            )}
          </code>
        </div>
      </footer>
    </>
  );
}
