import React from 'react';

import 'twin.macro';

import BlogPostTable from './BlogPostTable';

class Main extends React.PureComponent {
  render() {
    return (
      <div tw="flex flex-auto h-full flex-wrap overflow-y-auto bg-gray-100 max-md:pb-[68px] lg:block">
        <BlogPostTable />
      </div>
    );
  }
}

export default Main;
