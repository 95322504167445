import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'twin.macro';

import { AnimatePresence, motion } from 'framer-motion';

import Tooltip from '../Tooltip';
import SidebarLoading from './SidebarLoading';
import SidebarPortfolio from './SidebarPortfolio';
import SidebarSearch from './SidebarSearch';
import SidebarSwitch from './SidebarSwitch';

import { X } from 'lucide-react';

const Sidebar = ({ loading, portfolio, mobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [backdropActive, setBackdropActive] = useState(false);

  const modalState = {
    open: { y: 0 },
    closed: { y: '100%' },
  };

  const modalBackdrop = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };
  const [input, setInput] = useState('');

  const onInputChange = (value) => {
    setInput(value);
  };

  return mobile ? (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            tw="fixed bottom-0 z-40 flex w-full flex-col rounded-tl-lg rounded-tr-lg bg-white shadow-xl backdrop-blur-3xl h-[94%]"
            key="modalFrame"
            variants={modalState}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
          >
            <header tw="relative z-50 flex place-content-between items-center py-3 pl-4 pr-3">
              <span tw="font-bold text-lg">Värdepapper</span>
              <button
                tw="p-2"
                onClick={() => {
                  setIsOpen(false);
                  setBackdropActive(false);
                  onInputChange('');
                }}
              >
                <X color="black" size={24} />
              </button>
            </header>
            <SidebarSearch
              portfolio={portfolio}
              input={input}
              onInputChange={onInputChange}
            />
            <SidebarPortfolio searchValue={input} />
            {loading && <SidebarLoading />}
            {Object.keys(portfolio).length === 0 && <Tooltip />}
          </motion.aside>
        )}
        {backdropActive && (
          <motion.div
            tw="absolute inset-0 h-screen w-screen bg-black/80 z-[35]"
            key="modalBg"
            variants={modalBackdrop}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.3 }}
            onClick={() => {
              setIsOpen(!isOpen);
              setBackdropActive(!backdropActive);
            }}
          ></motion.div>
        )}
        <SidebarSwitch
          onClick={() => {
            setIsOpen(!isOpen);
            setBackdropActive(!backdropActive);
            onInputChange('');
          }}
          sidebarVisible={isOpen && true}
        />
      </AnimatePresence>
    </>
  ) : (
    <>
      <aside tw="hidden xl:(relative z-40 flex flex-none flex-col border-gray-200 border-r bg-white h-[100%] lg:w-[350px] xl:w-[450px])">
        <header tw="relative z-50 flex place-content-between items-center py-4 pl-4 pr-3">
          <span tw="font-bold text-base">Värdepapper</span>
        </header>
        <SidebarSearch
          portfolio={portfolio}
          input={input}
          onInputChange={onInputChange}
        />
        <SidebarPortfolio searchValue={input} />
        {loading && <SidebarLoading />}
        {Object.keys(portfolio).length === 0 && <Tooltip />}
      </aside>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.appReducer.loading,
    portfolio: state.portfolioReducer.portfolio,
  };
};

export default connect(mapStateToProps)(Sidebar);
