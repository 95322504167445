import React, { useState } from 'react';

import tw from 'twin.macro';

import ChevronDown from '../../images/icons/ic_chevron_down.svg';
import ChevronUp from '../../images/icons/ic_chevron_up.svg';

const Parent = tw.div`grid grid-cols-12 flex-wrap place-content-start`;
const Child = tw.div`col-span-12 xl:col-span-4`;
const Cell = tw.div`flex h-full flex-col space-y-2 border-l border-b px-4 py-4 font-io`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

const PortfolioSummary = ({ portfolio, total, da }) => {
  const [expandState, setExpandState] = useState(1);

  const totalPortfolio = portfolio.reduce(
    (acc, curr) => acc + curr.price * curr.quantity,
    0
  );

  return (
    <div tw="mb-3 block w-full bg-white">
      <Parent>
        <Child
          tw="col-span-12!"
          onClick={() => setExpandState(expandState === 1 ? 0 : 1)}
        >
          <Head tw="flex cursor-pointer place-content-between items-center">
            <p>Sammanfattning</p>
            <div tw="cursor-pointer">
              {expandState ? (
                <img src={ChevronUp} alt="" />
              ) : (
                <img src={ChevronDown} alt="" />
              )}
            </div>
          </Head>
        </Child>
        <Child css={[expandState ? tw`block` : tw`hidden`]}>
          <Cell tw="border-l-0">
            <div tw="font-sans">Portföljvärde</div>
            <div tw="text-left font-mono text-2xl md:text-3xl">
              {totalPortfolio.toFixed(2)}:-
            </div>
          </Cell>
        </Child>
        <Child css={[expandState ? tw`block` : tw`hidden`]}>
          <Cell tw="border-l-0 xl:border-l">
            <div tw="font-sans">Direktavkastning</div>
            <div tw="text-left font-mono text-2xl md:text-3xl">
              {da.toFixed(2)}%
            </div>
          </Cell>
        </Child>
        <Child css={[expandState ? tw`block` : tw`hidden`]}>
          <Cell tw="border-l-0 xl:border-l">
            <div tw="font-sans">Antal företag</div>
            <div tw="text-left font-mono text-2xl md:text-3xl">
              {portfolio.length}
            </div>
          </Cell>
        </Child>
      </Parent>
    </div>
  );
};

export default PortfolioSummary;
