import React from 'react';
import tw, { styled } from 'twin.macro';
import { Plus } from 'lucide-react';

const ImgContainer = styled.div(({ sidebarVisible }) => [
  tw`relative bottom-10 z-40 rounded-md bg-blue-600/90 p-4 text-white shadow-2xl backdrop-blur-md transition-all duration-300 xl:hidden`,

  sidebarVisible && tw`bottom-8 bg-blue-700 p-3 md:bottom-10`,
]);

const SVG = styled.div(({ sidebarVisible }) => [
  tw`block transition-all duration-300`,

  sidebarVisible && tw`rotate-45`,
]);
const SidebarSwitch = (props) => (
  <div tw="fixed bottom-0 left-0 right-0 z-40 flex h-10 w-screen place-content-center items-center md:(left-auto right-4 bottom-4 place-content-end)">
    <ImgContainer onClick={props.onClick} sidebarVisible={props.sidebarVisible}>
      <SVG sidebarVisible={props.sidebarVisible}>
        <Plus color="white" size={20} />
      </SVG>
    </ImgContainer>
  </div>
);

export default SidebarSwitch;
