const INITIAL_STATE = {
  backups: [],
};

function backupReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_BACKUP':
      return {
        ...state,
        backups: [...state.backups, { date: action.date, data: action.data }],
      };

    default:
      return state;
  }
}

export default backupReducer;
