import React from 'react';

import tw, { styled } from 'twin.macro';

const Notification = styled.div(({ type }) => [
  tw`space-y-2 rounded-sm border py-2 px-2.5 text-black text-sm`,

  type === 'success' && tw`border-green-200 bg-green-100`,
  type === 'error' && tw`border-red-200 bg-red-100`,
  type === 'warning' && tw`border-orange-200 bg-orange-100`,
  type === 'info' && tw`border-blue-200 bg-blue-100`,
]);

export default function Message({ children, type }) {
  return <Notification type={type}>{children}</Notification>;
}
