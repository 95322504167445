import React from 'react';
import 'twin.macro';

import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

const title = 'Exportera/importera data | Aktieutdelningar';
const desc =
  'Var med och testa nya versionen av Aktieutdelningar, utvecklad från grunden med bättre användarvänlighet och nya funktioner.';

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <Layout>
        <main tw="mt-0 h-full w-full overflow-y-auto py-8 pb-24">
          <div className="content" tw="m-auto max-w-xl space-y-3 text-base">
            <p tw="mb-8">
              <a href="/">Tillbaka till Aktieutdelningar</a>
            </p>
            <h1 tw="pb-2">
              <span role="img" aria-label="emoji">
                🚀
              </span>
              <span>Skriv upp dig som BETA-testare</span>
            </h1>
            <p>
              Efter en lång tid av utveckling har vi nu kommit in i ett läge där
              vi kan öppna upp beta-versionen av nya Aktieutdelningar. Appen är
              byggd från grunden med nya häftiga funktioner och förbättrad
              användarvänlighet.
            </p>
            <p>
              <ul>
                <li>Byggd på ny och modern teknik.</li>
                <li>Stöd för oändligt med enheter.</li>
                <li>Ny och fräsch design med förbättrad användarvänlighet.</li>
                <li>Utökad statistik över innehavet.</li>
                <li>Bättre stöd för värdepapper som saknas.</li>
                <li>... och mycket mer.</li>
              </ul>
            </p>
            <p>
              <a href="https://app.aktieutdelningar.co">
                Gå till nya Aktieutdelningar
              </a>
            </p>
            <h2 tw="pt-4">När kommer ni lansera?</h2>
            <p>
              På grund av ökat engagemang i våra vanliga dagsjobb, samt
              förändrade familjesituationer så kan vi inte utlova ett
              lanseringsdatum i dagsläget. Vi jobbar dock så mycket vi hinner
              med på nya appen och kommer att kommunicera ut mer information om
              arbetet löpande till de som har registrerat sig på nya appen.
            </p>
            <h2 tw="pt-4">Hur kan jag följa utvecklingen?</h2>
            <p>
              I dagsläget har vi ingen möjlighet för våra användare att följa
              utvecklingen men vi har fått in en del önskemål kring detta. När
              vi har möjlighet att arbeta mer än sporadiskt ska vi försöka få
              upp ett nyhetsflöde för att berätta mer om arbetets utveckling.
            </p>
            <h2 tw="pt-4">Vad kommer hända med denna sidan?</h2>
            <p>
              Den nuvarande version av Aktieutdelningar kommer att finnas kvar
              efter lanseringen. Ingen data kommer att försvinna för er som
              önskar fortsätta använda den.
            </p>
            <p>
              Vi kommer dock inte kunna prioritera att lägga till aktier på
              denna siten, eftersom det kräver manuellt arbete från vår sida.
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default FAQ;
