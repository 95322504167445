import React from 'react';
import { connect } from 'react-redux';

import tw from 'twin.macro';

import { getMonthList } from '../../helpers';

const Parent = tw.div`grid w-full grid-cols-1 border-gray-200 bg-white 2xl:grid-cols-12`;
const Child = tw.div`w-full border-gray-200 border-t border-b`;
const Cell = tw.div`flex h-full flex-row-reverse place-content-between items-center border-gray-200 border-r-0 border-b px-4 text-center xl:items-start 2xl:border-r`;
const Head = tw.div`col-span-12 bg-gray-50 py-2.5 text-center font-extrabold`;
const ChildGrow = tw.div`flex-1`;
const MonthSpan = tw.span`text-xs text-gray-600 2xl:hidden`;
const StockList = tw.ul`w-full space-y-1 break-all py-4 text-left font-mono lg:space-y-4 2xl:text-center`;

class StockTable extends React.PureComponent {
  getList = (month) => {
    if (Object.keys(this.props.portfolio).length !== 0) {
      const list = getMonthList(this.props.portfolio, month);
      let response = '-';
      if (list != null) {
        response = list.stocks.map((item, i) => (
          <li tw="m-0 text-left 2xl:(space-y-1 text-center)" key={i}>
            <div tw="inline-block text-gray-500 text-xs md:block">
              {item.name}
            </div>{' '}
            <div tw="m-0 inline-block text-xs md:block 2xl:text-sm">
              {parseFloat(list.dividendArray[i].toFixed(2))}:-
            </div>
          </li>
        ));
      }
      return response;
    } else {
      return '-';
    }
  };

  render() {
    return (
      <div tw="w-full">
        <Parent>
          <Child tw="col-span-12">
            <Head>
              <p>Bolagens utdelningar</p>
            </Head>
          </Child>
        </Parent>
        <Parent tw="mb-3">
          <ChildGrow>
            <Cell>
              <MonthSpan>Jan</MonthSpan>
              <StockList>{this.getList('2023-01')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Feb</MonthSpan>
              <StockList>{this.getList('2023-02')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Mar</MonthSpan>
              <StockList>{this.getList('2023-03')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Apr</MonthSpan>
              <StockList>{this.getList('2023-04')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Maj</MonthSpan>
              <StockList>{this.getList('2023-05')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Jun</MonthSpan>
              <StockList>{this.getList('2023-06')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Jul</MonthSpan>
              <StockList>{this.getList('2023-07')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Aug</MonthSpan>
              <StockList>{this.getList('2023-08')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Sep</MonthSpan>
              <StockList>{this.getList('2023-09')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Okt</MonthSpan>
              <StockList>{this.getList('2023-10')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell>
              <MonthSpan>Nov</MonthSpan>
              <StockList>{this.getList('2023-11')}</StockList>
            </Cell>
          </ChildGrow>
          <ChildGrow>
            <Cell tw="xl:border-r-0">
              <MonthSpan>Dec</MonthSpan>
              <StockList>{this.getList('2023-12')}</StockList>
            </Cell>
          </ChildGrow>
        </Parent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    portfolio: state.portfolioReducer.portfolio,
  };
}

export default connect(mapStateToProps)(StockTable);
