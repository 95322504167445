const INITIAL_STATE = {
  portfolio: [],
};

// Do backup of localstorage state
if (!localStorage.getItem('_stateBackup1')) {
  const state = localStorage.getItem('state');
  if (state) {
    localStorage.setItem('_stateBackup1', state);
  }
}

function portfolioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DATA_STOCK_REQUEST_SUCCESS':
      return {
        ...state,
        portfolio: [...state.portfolio, action.stock],
      };

    case 'EDIT_STOCK':
      return {
        ...state,
        portfolio: state.portfolio.map((item) =>
          item.api_id === action.api_id
            ? {
                ...item,
                quantity: action.quantity,
                value: (item.value / item.quantity) * action.quantity,
              }
            : item
        ),
      };

    case 'UPDATE_PORTFOLIO_SUCCESS':
      return {
        ...state,
        portfolio: state.portfolio.map((item) => {
          return item.api_id === action.api_id
            ? {
                ...item,
                sectors: action.sectors,
                price: action.price,
                value: action.price * item.quantity,
                dividends: [
                  ...action.dividends,
                  ...item.dividends.filter(
                    (d) => !action.dividends.find((i) => d.exDate === i.exDate)
                  ),
                ],
                events: action.events,
                currency_multiply: action.currency_multiply,
                country_code: action.country_code,
              }
            : item;
        }),
      };

    case 'DELETE_STOCK':
      return {
        ...state,
        portfolio: state.portfolio.filter(
          (item) => action.api_id !== item.api_id
        ),
      };

    case 'EDIT_GAV':
      return {
        ...state,
        portfolio: state.portfolio.map((item) =>
          item.api_id === action.api_id
            ? {
                ...item,
                gav: action.gav,
              }
            : item
        ),
      };

    case 'DELETE_DIVIDEND':
      return {
        ...state,
        portfolio: state.portfolio.map((item) => {
          if (item.api_id === action.api_id) {
            const dividends = item.dividends.filter((dividend) => {
              return !(
                dividend.exDate === action.exDate &&
                item.dividends.indexOf(dividend) === action.index
              );
            });
            return {
              ...item,
              dividends,
            };
          } else {
            return item;
          }
        }),
      };
    case 'ADD_DIVIDEND':
      return {
        ...state,
        portfolio: state.portfolio.map((item) =>
          item.api_id === action.api_id
            ? {
                ...item,
                dividends: [...item.dividends, action.newDividend],
              }
            : item
        ),
      };
    case 'EDIT_DIVIDEND':
      return {
        ...state,
        portfolio: state.portfolio.map((item) =>
          item.api_id === action.api_id
            ? {
                ...item,
                dividends: item.dividends.map((item, i) =>
                  i === action.index
                    ? {
                        ...item,
                        amount: action.amount,
                        api_id: action.api_id,
                      }
                    : item
                ),
              }
            : item
        ),
      };

    default:
      return state;
  }
}

export default portfolioReducer;
