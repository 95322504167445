import React from 'react';

import 'twin.macro';

export default function Layout({ children }) {
  return (
    <>
      <div tw="flex h-full w-full flex-row flex-nowrap place-content-start overflow-y-auto overflow-x-hidden">
        {children}
      </div>
    </>
  );
}
