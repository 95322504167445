import dayjs from 'dayjs';
import React, { useState } from 'react';

import tw from 'twin.macro';

import FlagComponent from '../General/Flag';
import ChevronDown from '../../images/icons/ic_chevron_down.svg';
import ChevronUp from '../../images/icons/ic_chevron_up.svg';

const Parent = tw.div`flex w-full flex-wrap place-content-start bg-white [:first-of-type]:border-t-0`;
const Child = tw.div`w-full`;
const Cell = tw.div`grid grid-cols-12 border-gray-200 border-t-0 font-mono`;
const Head = tw.div`border-gray-200 border-b bg-gray-50 px-4 py-2.5 font-extrabold`;

function formatDate(date) {
  const today = dayjs().startOf('day');
  const eventDate = dayjs(date);
  if (eventDate.isBefore(today.add(14, 'day'))) {
    const diff = eventDate.diff(today, 'day');
    return (
      <>
        <p>{date}</p>
        {diff > 0 ? (
          <p tw="font-semibold text-blue-600">Om {diff} dagar</p>
        ) : diff === 0 ? (
          <p tw="font-semibold text-blue-600">Idag</p>
        ) : (
          <p tw="text-gray-500">{Math.abs(diff)} dagar sedan</p>
        )}
      </>
    );
  }
  return eventDate.format('YYYY-MM-DD');
}

function sortEvents(data, sortFunction) {
  return data
    .filter((item) => item.events)
    .reduce((acc, item) => {
      return acc.concat(
        item.events.map((event) => ({ ...event, api_id: item.api_id }))
      );
    }, [])
    .sort(sortFunction);
}

function getEventType(eventType) {
  switch (eventType) {
    case 'ANNUAL_REPORT':
      return 'Årsrapport';
    case 'GENERAL_MEETING':
      return 'Bolagsstämma';
    case 'INTERIM_REPORT':
      return 'Delårsrapport';
    default:
      return 'Rapport';
  }
}

const MobileLabel = tw.span`block md:hidden`;

const ReportsTable = ({ data, sortOrder }) => {
  const [historicalEventsToggle, setHistoricalEventsToggle] = useState(1);
  const [upcomingEventsToggle, setUpcomingEventsToggle] = useState(1);

  const EventTable = ({ events, sortOrder }) => {
    const sortedEvents = events.sort((a, b) => {
      if (sortOrder === 'ASC') {
        return new Date(a.date) - new Date(b.date);
      } else if (sortOrder === 'DESC') {
        return new Date(b.date) - new Date(a.date);
      } else {
        return 0;
      }
    });

    return (
      <>
        {sortedEvents.map((event, index) => {
          const item = data.find((i) => i.api_id === event.api_id);
          if (!item) return null;
          return (
            <div
              key={index}
              tw="col-span-12 border-gray-200 border-b md:(grid grid-cols-12)"
            >
              <div tw="col-span-12 flex items-center space-x-4 border-gray-200 px-4 py-3 text-left max-md:(border-b bg-gray-50) md:(col-span-5 place-content-between)">
                <div tw="flex items-center space-x-1.5">
                  <FlagComponent country={item.country_code} />
                  <span>{item.name}</span>
                </div>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 max-md:border-b md:(col-span-4 border-l py-3)">
                <MobileLabel>Typ av rapport</MobileLabel>
                <span>{getEventType(event.type)}</span>
              </div>
              <div tw="col-span-12 flex flex-row place-content-between items-center py-3 px-4 md:(col-span-3 border-l py-3)">
                <MobileLabel>Datum</MobileLabel>
                <span>{formatDate(event.date)}</span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const sortFunction =
    sortOrder === 'ASC'
      ? (a, b) => new Date(a.date) - new Date(b.date)
      : (a, b) => new Date(b.date) - new Date(a.date);

  const sortedEvents = sortEvents(data, sortFunction);

  const historicalEvents = sortedEvents.filter(
    (event) => new Date(event.date) < dayjs().startOf('day')
  );
  const upcomingEvents = sortedEvents.filter(
    (event) => new Date(event.date) >= dayjs().startOf('day')
  );

  return (
    <div tw="block w-full">
      {sortedEvents.length > 0 ? (
        <>
          <Parent tw="mb-3">
            <Child
              tw="cursor-pointer"
              onClick={() => setUpcomingEventsToggle(!upcomingEventsToggle)}
            >
              <Head tw="flex place-content-between items-center">
                <p>Kommande rapporter</p>
                <div>
                  {upcomingEventsToggle ? (
                    <img alt="Slå ihop" src={ChevronUp} />
                  ) : (
                    <img alt="Expandera" src={ChevronDown} />
                  )}
                </div>
              </Head>
            </Child>
            <Child css={[upcomingEventsToggle ? tw`block` : tw`hidden`]}>
              <Cell tw="max-md:hidden">
                <div tw="col-span-5 border-gray-200 border-b py-2 px-4 text-left text-gray-500">
                  Företag
                </div>
                <div tw="col-span-4 border-gray-200 border-b border-l py-2 px-4 text-left text-gray-500">
                  Typ av rapport
                </div>
                <div tw="col-span-3 border-gray-200 border-b border-l py-2 px-4 text-left text-gray-500">
                  Datum
                </div>
              </Cell>

              <Cell>
                <EventTable events={upcomingEvents} sortOrder="ASC" />
              </Cell>
            </Child>
          </Parent>
          <Parent tw="mb-4 border-gray-200 border-gray-200 border-t border-b">
            <Child
              tw="cursor-pointer"
              onClick={() => setHistoricalEventsToggle(!historicalEventsToggle)}
            >
              <Head tw="flex place-content-between items-center">
                <p>Tidigare rapporter</p>
                <div>
                  {historicalEventsToggle ? (
                    <img alt="Slå ihop" src={ChevronUp} />
                  ) : (
                    <img alt="Expandera" src={ChevronDown} />
                  )}
                </div>
              </Head>
            </Child>
            <Child css={[historicalEventsToggle ? tw`block` : tw`hidden`]}>
              <Cell tw="max-md:hidden">
                <div tw="col-span-5 border-gray-200 border-b border-r py-2 px-4 text-left text-gray-500">
                  Företag
                </div>
                <div tw="col-span-4 border-gray-200 border-b border-r py-2 px-4 text-left text-gray-500">
                  Typ av rapport
                </div>
                <div tw="col-span-3 border-gray-200 border-b py-2 px-4 text-left text-gray-500">
                  Datum
                </div>
              </Cell>

              <Cell>
                <EventTable events={historicalEvents} sortOrder="DESC" />
              </Cell>
            </Child>
          </Parent>
        </>
      ) : (
        <Parent tw="mb-3">
          <Child>
            <Cell>
              <div tw="col-span-12 flex items-center border-b border-r-0 px-4 py-4 text-left">
                {data.length > 0 ? (
                  <>
                    Kunde inte hitta några rapporter. Testa att klicka på
                    "Uppdatera aktiekurser" till vänster.
                  </>
                ) : (
                  <>
                    Du behöver lägga till värdeppaper för att kunna se kommande
                    rapporter. Du kan göra det i listan till vänster.
                  </>
                )}
              </div>
            </Cell>
          </Child>
        </Parent>
      )}
    </div>
  );
};

export default ReportsTable;
